import React, { useContext, useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";

import { analytics, auth, dbfs } from "../firebase";
import { collection, doc, getDocs, setDoc, where } from "firebase/firestore";
import PropTypes from "prop-types";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { logEvent, setUserId } from "firebase/analytics";
import { query } from "firebase/database";
import { stripeProducts } from "../scoreboard-app/components/Initialise_Settings";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const lang = navigator?.language?.split("-")?.[0];

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [subscription, setSubscription] = useState(false);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [subscriptionLoad, setSubscriptionLoad] = useState(true);
  const [deviceName, setDeviceName] = useState("");
  const [signUpDetails, setSignUpDetails] = useState({
    fromHome: false,
    price: "",
  });

  const signup = async (
    email,
    password,
    name,
    emailSub,
    gtag_report_conversion
  ) =>
    new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then((data) => {
          if (data) {
            auth.languageCode = lang || "en";
            sendEmailVerification(data.user);
            configureProfile(data, name, email, emailSub);

            gtag_report_conversion();

            logEvent(analytics, "sign_up", {
              method: "Email", //
            });

            mixpanel.track("User Signed Up", {
              method: "email",
              plan: signUpDetails.fromHome
                ? signUpDetails?.price === stripeProducts.all.price_month
                  ? "monthly"
                  : signUpDetails?.price === stripeProducts.all.price_year
                  ? "yearly"
                  : ""
                : "",
            });

            mixpanel.identify(data.user.uid);

            mixpanel.people.set({
              $email: data.user.email, // Default Mixpanel email field
              "Sign Up Date": new Date().toISOString(),
            });

            if (signUpDetails.fromHome) {
              resolve(true);
            }
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(new Error(error.message));
        });
    });

  const configureProfile = (credential, name, email, emailSub) => {
    if (emailSub) {
      const emails = doc(dbfs, "emails", email);
      setDoc(emails, { email: email }, { merge: true });
    }

    try {
      updateProfile(credential.user, {
        displayName: name,
      });
    } catch (e) {
      //
    }

    const customers = doc(dbfs, "customers", credential.user.uid);
    setDoc(
      customers,
      {
        displayName: name,
        num_users: 1,
      },
      { merge: true }
    );
  };

  async function login(email, password) {
    let signIn = await signInWithEmailAndPassword(auth, email, password);
    return signIn;
  }

  function logout() {
    localStorage.clear();
    setCurrentUser();
    setSubscription();
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return updateEmail(currentUser, email);
  }

  function updateName(name) {
    return updateProfile(auth.currentUser, { displayName: name });
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function checkIsSubscribed(_currentUser) {
    return new Promise((resolve, reject) => {
      const customerRef = doc(
        dbfs,
        "customers",
        _currentUser?.uid || currentUser?.uid
      );
      const subscriptionsRef = collection(customerRef, "subscriptions");
      const q = query(
        subscriptionsRef,
        where("status", "in", ["active", "trialing"])
      );

      getDocs(q)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            let subscriptionExists = false;
            querySnapshot.forEach((doc) => {
              if (doc.exists()) {
                setSubscription(true);
                subscriptionExists = true;
              }
            });
            resolve(subscriptionExists);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (data) => {
      if (data) {
        console.log({ data });
        setCurrentUser(data);
        await checkIsSubscribed(data);
        setLoading(false);
        setUserId(analytics, data.uid);
        console.log("here1");
      } else {
        console.log("here2");
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [auth.onAuthStateChanged]);

  console.log({ signUpDetails });

  const value = {
    currentUser,
    setCurrentUser,
    checkIsSubscribed,
    loading,
    subscriptionLoad,
    login,
    signup,
    logout,
    subscription,
    resetPassword,
    updateEmail,
    updatePassword,
    updateName,
    deviceName,
    setDeviceName,
    signUpDetails,
    setSignUpDetails,
    setLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.object,
  setUID: PropTypes.func,
};
