/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Button } from "react-bootstrap";
import { analytics, dbfs } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import FullPageLoader from "../../components/PageLoading";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import NotFoundPage from "../404Error";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  where,
} from "firebase/firestore";
import { query } from "firebase/database";
import { logEvent } from "firebase/analytics";
import mixpanel from "mixpanel-browser";

const OrderConfirmation = () => {
  const [orderDetails, setOrderDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const { currentUser } = useAuth();
  let { sessionId } = useParams();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    async function getData() {
      let _notFound = false;

      const customerRef = doc(dbfs, "customers", currentUser?.uid);
      const checkoutSessionsQuery = query(
        collection(customerRef, "checkout_sessions"),
        where("sessionId", "==", sessionId),
        limit(1)
      );

      getDocs(checkoutSessionsQuery)
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              // Get the session_id from the most recent checkout_sessions document
              // Assuming you do something here with `doc.data()` or the document itself
              if (doc.exists()) {
                return;
              }
            });
          } else {
            _notFound = true;
            setNotFound(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setNotFound(true);
          setIsLoading(false);
          console.error("Error getting most recent checkout session: ", error);
        })
        .finally(() => {
          setIsLoading(false);
        });

      if (!_notFound) {
        const subscriptionsRef = collection(customerRef, "subscriptions");
        const subscriptionsQuery = query(
          subscriptionsRef,
          orderBy("created", "desc"),
          limit(1)
        );

        getDocs(subscriptionsQuery)
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(async (doc) => {
                const subscriptionData = doc.data();

                const planData = subscriptionData?.items?.[0]?.plan;
                const productData =
                  subscriptionData?.items?.[0]?.price?.product;

                const orderDetails = {
                  id: doc.id,
                  priceId: planData.id,
                  status: capitalizeFirstLetter(subscriptionData?.status),
                  price: planData?.amount / 100,
                  email: currentUser?.email,
                  productName: productData?.name + " Subscription",
                  currency: planData?.currency,
                  interval: planData?.interval,
                  created: new Date(subscriptionData?.created?.seconds * 1000),
                  trialPeriod: planData?.trial_period_days,
                };

                googleEcommerceTag(orderDetails);

                mixpanel.track("Completed Purchase", {
                  transaction_id: doc.id, // Unique transaction ID
                  user_id: currentUser?.uid, // Firebase UID
                  email: currentUser?.email,
                  price: orderDetails.priceId,
                  total_price: planData?.amount / 100,
                  currency: orderDetails?.currency,
                  product: productData?.name + " Subscription",
                  status: orderDetails?.status,
                  payment_status: "Success",
                });

                mixpanel.identify(currentUser?.uid);

                logEvent(analytics, "purchase", {
                  transaction_id: doc.id, // A unique ID for the transaction
                  value: planData?.amount / 100, // Total amount of the purchase
                  currency: planData?.currency, // Currency of the purchase
                  items: [
                    {
                      item_id: orderDetails.priceId, // ID of the purchased item
                      item_name: productData?.name + " Subscription", // Name of the purchased item
                      item_category: "Sports Subscription", // Category of the purchased item
                      price: planData?.amount / 100, // Price of the item
                      quantity: 1, // Quantity of the item purchased
                    },
                  ],
                });

                setOrderDetails(orderDetails);
              });
            } else {
              setNotFound(true);
              console.log("No subscriptions found.");
            }
          })
          .catch((error) => {
            setNotFound(true);
            console.error("Error retrieving subscriptions:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
    if (sessionId.length > 10) {
      getData();
    }
  }, [sessionId]);

  // eslint-disable-next-line no-unused-vars
  const googleEcommerceTag = (orderDetails) => {
    const dataLayer = window.dataLayer;

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: orderDetails?.id, // Transaction ID. Required for purchases and refunds.
            affiliation: "Online",
            revenue: orderDetails?.price, // Total transaction value (incl. tax and shipping)
          },
          products: [
            {
              // List of productFieldObjects.
              name: orderDetails?.productName, // Name or ID is required.
              id: orderDetails?.priceId,
              price: orderDetails?.price,
              brand: "OBScoreboard",
              category: "Subscription",
              variant: orderDetails?.interval,
              quantity: 1,
            },
          ],
        },
      },
    });
  };

  const confirmationContainer = {
    maxWidth: 450,
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
  };

  if (isLoading) return <FullPageLoader />;

  if (notFound) return <NotFoundPage />;

  return (
    <div className="mt-4 d-flex justify-content-center">
      <div className="text-center px-2 py-5" style={confirmationContainer}>
        <AiOutlineCheckCircle size={50} color="green" className="mt-4" />
        <h2>Welcome to OBScoreboard</h2>

        <div className="mt-4 text-left px-5">
          <h5>Order Details</h5>
          <div className="row">
            <div className="col-3">
              <strong>Status:</strong>
            </div>
            <div className="col-9">{orderDetails?.status}</div>
          </div>
          <div className="row">
            <div className="col-3">
              <strong>Price:</strong>
            </div>
            <div className="col-9">
              {orderDetails?.price
                ? Intl.NumberFormat(navigator.language || "en-US", {
                    style: "currency",
                    currency: orderDetails?.currency,
                  }).format(orderDetails?.price)
                : null}{" "}
              <i>
                {
                  "(No charge during trial period; charge applies after trial ends unless cancelled)"
                }
              </i>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <strong>Email:</strong>
            </div>
            <div className="col-9">{orderDetails?.email}</div>
          </div>
          <div className="row">
            <div className="col-3">
              <strong>Product:</strong>
            </div>
            <div className="col-9">{orderDetails?.productName}</div>
          </div>
        </div>

        <div className="mt-4">
          <Link to={"/dashboard"}>
            <Button
              variant="primary"
              className="w-80 mb-4"
              style={{ width: "80%" }}
            >
              Get Started
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
