import React, { useEffect } from "react";
import { AuthProvider } from "../contexts/AuthContext";
import { SubscriptionProvider } from "../contexts/SubscriptionContext";
import Routes from "./Routes";
import IntlProvider from "../contexts/IntlProvider";
import FirebaseProvider from "../contexts/FirebaseProvider";
import { ToastContainer } from "react-toastify";
import mixpanel from "mixpanel-browser";

function App() {
  useEffect(() => {
    mixpanel.init("56813eba6fcf73439f6fcdeb4dde1e02", {
      debug: false,
      track_pageview: true,
      persistence: "localStorage",
      ignore_dnt: true,
      cookie_domain: ".obscoreboard.com",
    });
  }, []);

  return (
    <div>
      <ToastContainer />
      <FirebaseProvider>
        <IntlProvider>
          <AuthProvider>
            <SubscriptionProvider>
              <Routes />
            </SubscriptionProvider>
          </AuthProvider>
        </IntlProvider>
      </FirebaseProvider>
    </div>
  );
}

export default App;
